<template>
  <div class="cart">
    <div class="title">购物车</div>

    <div class="address">
      <van-cell title="收货地址" value="添加地址" is-link @click="tolink('address')" v-if="addressList.length == 0" />
      <van-cell title="收货地址" value="选择地址" is-link @click="show = true" v-else />
      <div class="row items-center q-mt-sm" v-if="addressList.length > 0">
        <q-icon name="room" size="26px" color="grey"></q-icon>
        <div class="col q-ml-sm">
          <div>{{ address.username }} {{ address.usertel }}</div>
          <div class="address-details">{{ address.sheng+address.shi+address.xian+address.address }}</div>
        </div>
      </div>
    </div>

    <div class="goods">
      <div class="goods-item" v-for="(item, index) in goodslist" :key="item.id">
        <van-checkbox v-model="item.gchecked" checked-color="#ff2d2e" @change="checkedChange">
          <div class="row">
            <img :src="getimg(item.sku.img[0])" width="70" height="70" />
            <div class="col q-ml-sm">
              <div class="goods-name">{{ item.goodsName }}</div>
              <div class="goods-sku">规格：{{item.sku.name}}</div>
              <div class="goods-price">¥{{ item.sku.price }}</div>
            </div>
          </div>
        </van-checkbox>
        <div class="row justify-end items-center q-mt-sm">
          <van-stepper v-model="item.num" />
          <q-icon name="delete_outline" size="24px" color="grey" style="margin-left: 10px;" @click="delGoods(index)"></q-icon>
        </div>
      </div>
    </div>

    <van-submit-bar :price="sumPrice * 100" label="合计：" button-text="提交订单" @submit="onSubmit">
      <van-checkbox v-model="allchecked" checked-color="#ff2d2e" @click="allCheckedChange">全选</van-checkbox>
    </van-submit-bar>

    <!-- 选择地址 -->
    <q-dialog v-model="show" position="bottom">
      <div class="dialog">
        <div class="dialog-head">
          <div class="text-grey" @click="tolink('address')">添加新地址</div>
          <q-icon name="close" color="grey" size="18px" @click="show = false"></q-icon>
        </div>
        <div class="q-mt-sm">
          <div class="row items-center q-mt-sm" v-for="item in addressList" :key="item.id" @click="selectAddress(item)">
            <q-icon name="room" size="26px" color="grey"></q-icon>
            <div class="col q-my-xs">
              <div>{{ item.username }} {{ item.usertel }}</div>
              <div class="address-details">{{ item.sheng+item.shi+item.xian+item.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </q-dialog>

    <TabBar active="2" />
  </div>
</template>

<script>
import { Cell, Checkbox, SubmitBar, Toast } from 'vant';
import TabBar from '@/components/TabBar.vue'
import { ref } from 'vue';

export default {
  components: {
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    TabBar
  },
  setup() {
    return {
      us: ref(null),
      addressList: ref([]),
      address: ref({}),
      goodslist: ref([]),
      allchecked: ref(false),
      sumPrice: ref(0),
      goodsnum: ref(0),
      show: ref(false)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.goodslist = JSON.parse(this.$utils.getloc('goodslist'))??[]
    this.getaddress()
    this.jsPrice();
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "upload/" + img;
			}
		},
    checkedChange() {
      this.$utils.setloc('goodslist', JSON.stringify(this.goodslists));
      this.jsPrice()
    },
    // 全选
    allCheckedChange() {
      if (this.allchecked) {
        this.goodslist.forEach((item) => {
          item.gchecked = true;
        });
      } else {
        this.goodslist.forEach((item) => {
          item.gchecked = false;
        });
      }
      this.jsPrice();
    },
    delGoods(index) {
      this.goodslist.splice(index, 1);
      this.$utils.setloc('goodslist', JSON.stringify(this.goodslist));
      this.jsPrice();
      Toast('删除成功')
    },
    // 计算总价
    jsPrice() {
      let i = 0;
			this.sumPrice = 0;
			this.goodsnum = 0;

			this.goodslist.forEach((item) => {
				this.goodsnum += 1;
				if (item.gchecked) {
					i++;
					this.sumPrice += item.sku.price * item.num;
				}
			});

			if (i == this.goodslist.length) {
				this.allchecked = true;
			} else {
				this.allchecked = false;
			}
			this.$utils.setloc('goodslist', JSON.stringify(this.goodslist));
    },
    selectAddress(e) {
      this.address = e
      this.show = false
    },
    getaddress() {
      let _this = this
      _this.$request.post(
        "Api/UsersAddress/List",
        {
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            let address = data.find(item => item.isdefault == 1)
            if(!address && data.length > 0) {
              address = data[0]
            }
            _this.address = address
            _this.addressList = data
          }
        }
      )
    },
    onSubmit() {
      let _this = this
      if(!this.address) {
        Toast("请添加收货地址")
        return
      }
      let glist = JSON.parse(JSON.stringify(this.goodslist))
      let skuList = []
      let numList = []
      glist.forEach(item => {
        if(item.gchecked) {
          item.sku.img = item.sku.img.toString()
          item.sku.slide = JSON.stringify(item.sku.slide)
          skuList.push(item.sku)
          numList.push(item.num)
        }
      })
      if (skuList.length == 0 || numList.length == 0) {
        Toast("请选择要下单的商品")
        return;
      }
      _this.$q.dialog({
				message: "确定要下订单吗",
        cancel: true,
			}).onOk(() => {
        _this.$request.post(
          "Api/ShopOrder/Add",
          {
            
            userid: _this.us.userid,
            aid: _this.address.id,
						shopGoodsModelList: JSON.stringify(skuList),
						numList: JSON.stringify(numList),
						beizhu: ""
          },
          (res) => {
            if(res.data.code == 0) {
              _this.$q.dialog({
                message: res.data.msg,
              })
              return
            }
            Toast(res.data.msg)
            _this.goodslist = _this.goodslist.filter(item => item.gchecked == false)
            _this.$utils.setloc('goodslist', JSON.stringify(_this.goodslist));
            _this.jsPrice()
            _this.$router.push('shopOrder')
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.cart {
  min-height: 100vh;
  padding: 10px 10px 60px;
  box-sizing: border-box;
  background: rgba(242, 242, 242, 1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: rgba(255, 45, 46, 1);
}

.address {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.address-details {
  font-size: 12px;
  color: gray;
  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.goods-item {
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.goods-name {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.goods-sku {
  margin-top: 2px;
	font-size: 12px;
	color: gray;
}

.goods-price {
  margin-top: 4px;
	color: #ff2d2e;
}

.cart :deep(.van-submit-bar) {
  bottom: 56px;
}

.dialog {
  padding: 10px;
  background-color: #fff;
}

.dialog-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>